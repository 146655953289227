/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version,not dead
*/
* {
  /* margin: 0;
  padding: 0; */
  box-sizing: border-box;
}
#canvas {
  z-index: -100;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.8;
  background-color: #fff;
  /* height: 100vh; */
}
.App {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  padding: 0;
  left: 0;
  right: 0;
}

.narkopolo-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
#LogoHeader {
  height: 45vh;
}
.company-logo {
  width: 97%;
  height: auto;
  pointer-events: none;
  -webkit-animation: rotate 6s infinite linear;
  animation: rotate 6s infinite linear;
}
.timer {
  color: black;
  font-size: clamp(2.5rem, 2.3611rem + 0.7407vw, 3.75rem);
}
.form-container {
  height: 45vh;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: start;
}

.form-header {
  height: auto;
  gap: 0.25rem;
  font-weight: 900;
  font-size: clamp(1.0625rem, 0.8472rem + 1.1481vw, 3rem);

  text-align: center;
  display: flex;
  flex-wrap: wrap;
  p {
    height: auto;
    text-align: center;
    margin-bottom: 0;
  }
}
.form-inputs {
  gap: 0.87rem;
  height: auto;
  /* margin: auto; */
  display: flex;
  padding: 0.75rem;
  flex-wrap: wrap;
  flex-direction: column;

  input {
    border: 2px solid black;
    height: 35px;
    border-radius: 15%;
    box-shadow: 2px 2px 0px 0px, 3px 3px 0px 0px, 4px 4px 0px 0px,
      5px 5px 0px 0px, 6px 6px 0px 0px;
  }
  ::placeholder {
    text-align: center;
    color: black;
    font-weight: 700;
  }
}

.form-agreed {
  color: gainsboro;
  font-size: 6px;
  text-align: justify;
}
.incorrrectValue {
  font-size: 12px;
  font-weight: 900;
  border: 3px dotted red;
  border-top: none;
  margin-bottom: 0;
}
/* CSS */
.button-54 {
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  letter-spacing: 2px;
  text-decoration: none;
  text-transform: uppercase;
  color: #000;
  cursor: pointer;
  border: 3px solid;
  padding: 0.25em 0.5em;
  box-shadow: 1px 1px 0px 0px, 2px 2px 0px 0px, 3px 3px 0px 0px, 4px 4px 0px 0px,
    5px 5px 0px 0px;
  position: relative;
  -moz-user-select: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  height: 50px;
  font-weight: 700;
}

.button-54:active {
  box-shadow: 0px 0px 0px 0px;
  top: 5px;
  left: 5px;
}

@media (min-width: 768px) {
  .button-54 {
    padding: 0.25em 0.75em;
  }
}
#SocialRights {
  height: 10vh;
  white-space: nowrap;
}
.social-media-container {
  display: flex;
  gap: 0.5rem;
  font-weight: 900;
  justify-content: center;
  /* margin-top: 0.875rem; */
  font-size: 1.5rem;
  height: 50%;
  width: auto;
}
.FaTiktok {
  filter: drop-shadow(3px 5px 2px rgb(255 0 80 / 0.4));
  fill: black;
  stroke-width: 0.25rem;
  stroke: #00f2ea;
  font-size: 1.5rem;
  height: 1.5rem;
}
.FaInstagram {
  fill: black;
  font-size: 1.5rem;
  height: 1.5rem;
}
.copyrights-container {
  font-weight: 700;
  font-size: 0.875rem;
  flex-wrap: nowrap;
  gap: 20px;
  text-align: center;
  padding-top: 0.25rem;
  height: 50%;
}
.fish-link {
  text-decoration: none;
  color: black;
}
.comunication-container {
  font-weight: 700;
}
@-webkit-keyframes rotate {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}
@keyframes rotate {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}

@media screen and (min-width: 576px) {
  .App {
    width: 99%;
  }
  .company-logo {
    width: 59%;
  }
}
@media screen and (min-width: 991px) {
  .company-logo {
    width: 39%;
  }
  .form-container {
    align-items: end;
  }
}
.timeLeftContainer {
  /* font-size: clamp(1.2rem, 1.0875rem + 0.6vw, 1.8rem); */
  /* font-size: 24px; */
  /* font-size: 16px; */
  font-size: clamp(1rem, 0.9063rem + 0.5vw, 1.5rem);
}
